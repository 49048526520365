<template>
  <div class="Loginwechat">
  </div>
</template>

<script>
import { GetWMImageState, RedirectWX } from "@/api/user.js";
import { ApiToken } from '@/utils/ApiToken'

export default {
  name: "Loginwechat",
  data() {
    return {
      pathId: '',
    };
  },
  mounted() {
    this.pathId = this.$route.query.wmId;
    GetWMImageState(this.pathId).then(res => {
      if(res.status == 1) {
        if(res.data.state == 0){
          this.redirectWXs();
        } else if(res.data.state == 1) {
          this.$toast(res.message);
          setTimeout(() => {
            this.$router.push("/Login");
          }, 800);
        } else {
          this.$toast({"message": res.message, duration: 0});
        }
      }else {
        this.$toast(res.message);
      }
    })
  },
  methods: {
    redirectWXs() {
      const urls = {
        "wmId": this.pathId,
        "rediectUrl":  ApiToken.shareUrl + "LoginWXRedirect?wmid=" + this.pathId,
      }
      RedirectWX(urls).then( res => {
        if(res.status == 1) {
          window.location.href = res.data;
        } else {
          this.$toast({"message": res.message, duration: 0});
        }
      })
    }
  }
}
</script>

<style>
</style>